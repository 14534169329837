<template>
  <div class="galery-wrap fade-in-top">
      <GaleryVideos/>
  </div>  
</template>

<script>
import GaleryVideos from "@/components/GaleryVideos.vue";

export default {
  name: "Galery",
  components: {
    GaleryVideos
  },
  data() {
    return{
    }
  }
}

</script>

<style lang="scss" scoped>
  .galery-wrap {
    margin: 105px auto 0;
    width: 75%;
    padding: 20px 10px;
    border-top: 1px solid #7395ae96;
    border-bottom: 1px solid #7395ae96;
    h2 {
      margin: 5px;
    }
  }
  
  @media screen and (max-width: 800px) {
    .products-wrap {
      width: 90%;
    }
  }

</style>