<template>
    <h2 class="fade-in-top">Video snimci: </h2>
    <div class="before-and-after-videos fade-in-top">
      <div>
          <video width="500" height="300" controls>
            <source src="../assets/videos/BlasShowcase.mp4" type="video/mp4">
        </video>
      </div>
      <div>
          <video width="500" height="300" controls>
            <source src="../assets/videos/HannahShowcase.mp4" type="video/mp4">
        </video>
      </div>
    </div>
</template>

<script>

export default {
  data() {
    return{
    }
  }
}

</script>

<style lang="scss" scoped>
  .before-and-after-videos {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    div {
        border-radius: 3px;
        margin: 10px 0;
        width: 50%;
        display: flex;
        justify-content: center;
      video {
        border-radius: 3px;
      }
    }
  }
  
  @media screen and (max-width: 1400px) {
    .before-and-after-videos {
        flex-direction: column;        
        video {
            width: 400px;
        }
    }
  }

</style>